import React, { useState } from "react";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import {
  Button,
  Card,
  CardBody,
  Col,
  Input,
  Label,
  Row,
  TabContent
} from "reactstrap";
import CardHeaderTabs from "../../../../@meds/components/molecules/card-header-tabs";
import { Pending } from "./tabs/pending";
import { Evaluated } from "./tabs/evaluated";

export const PatientRelationship = ({ history }) => {
  const {
    state: { original }
  } = useLocation();
  const [activeTab, setActiveTab] = useState("1");
  const tabs = [
    {
      id: "1",
      name: "Pendientes"
    },
    {
      id: "2",
      name: "Evaluados"
    }
  ];
  return (
    <div className="fadeIn animated">
      <Card>
        <CardHeaderTabs
          active={activeTab}
          onClick={tap => setActiveTab(tap)}
          tabs={tabs}
        />
        <CardBody>
          <Row style={{ marginBottom: "20px" }}>
            <Col md="2">
              <Label>LPC o Jornada</Label>
              <Input value={original.name} disabled={true} />
            </Col>
            {activeTab !== "2" && (
              <Col md="2" style={{ display: "flex", alignItems: "flex-end" }}>
                <Button
                  color="success"
                  onClick={() => {
                    history.push("/lpc/medical_appointment/appointment", {
                      original
                    });
                  }}
                >
                  Iniciar Cita
                </Button>
              </Col>
            )}
          </Row>
          <TabContent className="border-0" activeTab={activeTab}>
            {activeTab === "1" && <Pending lpc={original} history={history} />}
            {activeTab === "2" && <Evaluated lpc={original} />}
          </TabContent>
          {/*Back button */}
          <Row style={{ marginTop: "20px" }}>
            <Col>
              <Button
                className=""
                onClick={() => {
                  history.goBack();
                }}
              >
                Atras
              </Button>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </div>
  );
};
