import React, { useEffect } from "react";
import ReactTable from "react-table-v6";
import { GET_LPC_PARAMETERS } from "../../../../utils/queries";
import { useQuery } from "react-apollo-hooks";
import { Input } from "reactstrap";
import ReactSelect from "react-select";
import NumberFormatInput from "react-number-format";
import { useLpcStore } from "../../../../hooks/useLpcStore";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

export const AppointmentGroups = ({ groupId, patientId, eventId }) => {
  const { id } = useParams();
  const {
    parameters,
    values,
    observations,
    setParameters2,
    setValue,
    setObservation
  } = useLpcStore();
  const { data, isLoading, refetch } = useQuery(GET_LPC_PARAMETERS, {
    fetchPolicy: "network-only",
    skip: groupId === 0,
    variables: {
      where: {
        id: groupId,
        patientId,
        editMode: id ? true : false,
        eventId
      }
    }
  });

  const handleChange = (id, type, newValue) => {
    setValue(id, type, newValue);
  };

  const binary = [
    { value: 1, label: "Positivo" },
    { value: 2, label: "Negativo" }
  ];

  const YesOrNo = [
    { value: 1, label: "Sí" },
    { value: 2, label: "No" }
  ];

  const validateYesorNo = yesorno => {
    if (!yesorno) return null;
    return yesorno ? 1 : 2;
  };

  const content = (type, id, original) => {
    const existingValue = values.find(v => v.id === id)?.value;

    switch (type) {
      case 1:
        return (
          <ReactSelect
            defaultValue={
              existingValue !== undefined
                ? binary.find(e => e.value === existingValue)
                : binary.find(
                    e =>
                      e.value === (original.result?.positiveOrNegative ? 1 : 2)
                  )
            }
            options={binary}
            placeholder="Seleccionar respuesta"
            onChange={e => handleChange(id, type, e.value)}
          />
        );
      case 2:
        return (
          <ReactSelect
            defaultValue={
              existingValue !== undefined
                ? YesOrNo.find(e => e.value === existingValue)
                : YesOrNo.find(
                    e => e.value === validateYesorNo(original.result?.yesOrNo)
                  )
            }
            options={YesOrNo}
            placeholder="Seleccionar respuesta"
            onChange={e => handleChange(id, type, e.value)}
          />
        );
      case 3:
      case 4:
        return (
          <NumberFormatInput
            decimalScale={2}
            fixedDecimalScale={true}
            defaultValue={
              existingValue !== undefined
                ? existingValue
                : original.result?.absolute || original.result?.percentage || 0
            }
            thousandSeparator={true}
            className="form-control"
            placeholder="Seleccionar respuesta"
            onValueChange={({ floatValue }) =>
              handleChange(id, type, floatValue)
            }
          />
        );
      case 5:
        return (
          <Input
            type="date"
            className="form-control"
            defaultValue={
              existingValue !== undefined
                ? existingValue
                : original.result?.date?.split("T")[0] || ""
            }
            onChange={({ target }) => handleChange(id, type, target.value)}
          />
        );
      case 6:
        return (
          <Input
            className="form-control"
            defaultValue={
              existingValue !== undefined
                ? existingValue
                : original.result?.text || ""
            }
            onChange={({ target }) => handleChange(id, type, target.value)}
          />
        );
      default:
        return "N/A";
    }
  };

  useEffect(() => {
    if (!isLoading && data) {
      setParameters2(data.getParametersLpc);
    }
  }, [data, isLoading, setParameters2]);

  useEffect(() => {
    refetch();
  }, [patientId, refetch]);

  return (
    <div className="fadeIn animated">
      <ReactTable
        data={parameters ?? []}
        loading={isLoading}
        defaultPageSize={10}
        columns={[
          {
            Header: "Parámetro",
            accessor: "name",
            style: {
              wordBreak: "break-all",
              overflowWrap: "break-word",
              whiteSpace: "pre-wrap"
            }
          },
          {
            Header: "Und Medida",
            accessor: "unitOfMeasurement"
          },
          {
            Header: "Resultado",
            Cell: ({ original }) => (
              <div>{content(original.typeId, original.id, original)}</div>
            ),
            maxWidth: 300
          },
          {
            Header: "Min",
            accessor: "minimum",
            maxWidth: 150,
            Cell: ({ original }) => {
              let message;
              if (original.typeId === 1) {
                message = original.positiveOrNegative ? "Positivo" : "Negativo";
                return <div className="text-left">{message}</div>;
              } else if (original.typeId === 2) {
                message = original.yesOrNo ? "Si" : "No";
                return <div className="text-left">{message}</div>;
              } else if (original.typeId === 5) {
                return <div className="text-left">{original.date}</div>;
              } else if (original.typeId === 6) {
                return <div className="text-left">{original.text}</div>;
              } else if (original.typeId === 3) {
                return (
                  <NumberFormatInput
                    suffix={"%"}
                    decimalScale={2}
                    displayType={"text"}
                    fixedDecimalScale={true}
                    thousandSeparator={true}
                    value={original.minimum}
                  />
                );
              } else if (original.typeId === 4) {
                return (
                  <NumberFormatInput
                    decimalScale={2}
                    displayType={"text"}
                    fixedDecimalScale={true}
                    thousandSeparator={true}
                    value={original.minimum}
                  />
                );
              } else {
                return <div></div>;
              }
            }
          },
          {
            Header: "Max",
            accessor: "maximum",
            maxWidth: 150,
            Cell: ({ original }) => {
              if (original.typeId === 3) {
                return (
                  <NumberFormatInput
                    suffix={"%"}
                    decimalScale={2}
                    displayType={"text"}
                    fixedDecimalScale={true}
                    thousandSeparator={true}
                    value={original.maximum}
                  />
                );
              } else if (original.typeId === 4) {
                return (
                  <NumberFormatInput
                    decimalScale={2}
                    displayType={"text"}
                    fixedDecimalScale={true}
                    thousandSeparator={true}
                    value={original.maximum}
                  />
                );
              } else {
                return <div></div>;
              }
            }
          },
          {
            Header: "Observación",
            Cell: ({ original }) => (
              <Input
                placeholder="Observación"
                defaultValue={
                  observations.find(o => o.id === original.id)?.value || ""
                }
                onChange={({ target }) =>
                  setObservation(original.id, target.value)
                }
              />
            )
          }
        ]}
      />
    </div>
  );
};
