import { lazy } from "react";
const Index = lazy(() => import("./pages"));

const routes = [
  {
    name: "KPI's - Estadísticas de Atenciones",
    component: Index,
    exact: true,
    path: "/dashboard/operational-metrics"
  }
];

export default routes;
