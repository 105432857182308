import React, { useEffect, useState } from "react";
import ReactSelect from "react-select";
import {
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText
} from "reactstrap";
import AsyncSelect from "react-select/async";
import { debounce, get } from "lodash";
import { GET_ICD10_CODES } from "../../../../../Appointment/views/appointment/queries";
import { useApolloClient, useQuery } from "react-apollo-hooks";
import { GET_LPC_ATTENTION_PROGRAMS } from "../../../../utils/queries";
import { useLpcStore } from "../../../../hooks/useLpcStore";

export const PreliminaryDiagnosis = () => {
  const client = useApolloClient();

  function loadICD10() {
    return debounce((inputValue, cb) => {
      if (inputValue.length < 2) {
        cb([]);
      } else {
        client
          .query({
            query: GET_ICD10_CODES,
            variables: { where: { description: inputValue } }
          })
          .then(data => {
            cb(get(data, "data.icd10Codes") || []);
          })
          .catch(() => {
            cb([]);
          });
      }
    }, 500);
  }
  const {
    ars,
    setIcdCodeId,
    setProgramId,
    programId,
    setFields,
    diagnostic
  } = useLpcStore();

  const [arses, setArses] = useState([{ value: 0, label: "No Aplica" }]);

  const { data, isLoading, refetch } = useQuery(GET_LPC_ATTENTION_PROGRAMS, {
    fetchPolicy: "network-only",
    variables: {
      where: {
        id: ars
      }
    }
  });

  useEffect(() => {
    if (data && !isLoading) {
      if (data.getLpcAttentionPrograms?.length > 0) {
        // const
        setArses(prev => prev.concat(data.getLpcAttentionPrograms));
      }
    }
  }, [data, isLoading]);

  useEffect(() => {
    refetch({
      variables: {
        where: {
          id: ars
        }
      }
    });
    // eslint-disable-next-line
  }, [ars]);

  return (
    <div className="fadeIn animated" style={{ marginTop: "15px" }}>
      <FormGroup>
        <InputGroup
          style={{ width: "50%", marginBottom: "15px" }}
          className="ml-2 mr-3 select-content"
        >
          <InputGroupAddon addonType="prepend">
            <InputGroupText>{"Diagnóstico"}</InputGroupText>
          </InputGroupAddon>
          <div className="form-control p-0">
            <AsyncSelect
              placeholder={"Selecciona un Diagnóstico"}
              getOptionValue={option => option}
              defaultValue={diagnostic}
              getOptionLabel={option => option.description}
              loadOptions={loadICD10()}
              className={"text-left"}
              onChange={selected => {
                console.log(selected);
                setFields({
                  diagnostic: selected
                });
                setIcdCodeId(selected.id);
              }}
            />
          </div>
        </InputGroup>
        <InputGroup
          style={{ width: "50%" }}
          className="ml-2 mr-3 select-content"
        >
          <InputGroupAddon addonType="prepend">
            <InputGroupText>{"Programa de Atención "}</InputGroupText>
          </InputGroupAddon>
          <div className="form-control p-0">
            <ReactSelect
              options={arses}
              value={arses?.find(e => e?.value === programId) ?? null}
              loading={isLoading}
              isDisabled={isLoading}
              onChange={e => {
                if (e) {
                  setProgramId(e.value);
                }
              }}
            />
          </div>
        </InputGroup>
      </FormGroup>
    </div>
  );
};
