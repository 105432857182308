import React from "react";

import { Nav, NavItem, NavLink, CardHeader } from "reactstrap";

export default function CardHeaderTabs({ active, tabs, onClick, showActive }) {
  const components = tabs
    .filter(tab => {
      if (!showActive) return true;
      return active === tab.id;
    })
    .map(tab => {
      return (
        <NavItem key={tab.id}>
          <NavLink active={active === tab.id} onClick={() => onClick(tab.id)}>
            <span> {tab.name}</span>
          </NavLink>
        </NavItem>
      );
    });

  return (
    <CardHeader>
      <Nav tabs>{components}</Nav>
    </CardHeader>
  );
}
