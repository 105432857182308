import React from "react";
import { useQuery } from "react-apollo-hooks";
import ReactTable from "react-table-v6";
import { GET_EVALUATED_CONSULTATION } from "../../../../utils/queries";

export const Evaluated = ({ lpc }) => {
  const { data, isLoading } = useQuery(GET_EVALUATED_CONSULTATION, {
    fetchPolicy: "network-only",
    variables: {
      where: {
        id: lpc.id
      }
    }
  });

  return (
    <div className="fadeIn animated">
      <ReactTable
        loading={isLoading}
        data={data.getEvaluatedConsultation || []}
        defaultPageSize={10}
        columns={[
          {
            Header: "Paciente",
            accessor: "name"
          },
          {
            Header: "Cedula",
            accessor: "document",
            maxWidth: 150
          },
          {
            Header: "Edad",
            accessor: "age",
            maxWidth: 100
          },
          {
            Header: "Sexo",
            accessor: "gender",
            maxWidth: 100
          },
          {
            Header: "Programa de Atención",
            accessor: "AttentionProgram",
            className: "text-center",
            Cell: ({ original }) => {
              return <div>{original?.AttentionProgram ?? "No Aplica"}</div>;
            }
          }
        ]}
      />
    </div>
  );
};
