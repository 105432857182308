import { lazy } from "react";

const MainPage = lazy(() => import("./page"));

const routes = [
  {
    path: "/dashboard/kpi-attentions",
    component: MainPage,
    exact: false,
    name: "KPI's - Atenciones"
  }
];

export default routes;
