import React from "react";
import { Button } from "reactstrap";
import ReactTable from "react-table-v6";
import { useMutation, useQuery } from "react-apollo-hooks";
import {
  GET_PENDING_CONSULTATION,
  UPDATE_STATE_LPC_CONSULTATION
} from "../../../../utils/queries";

export const Pending = ({ lpc, history }) => {
  const { data, isLoading, refetch } = useQuery(GET_PENDING_CONSULTATION, {
    fetchPolicy: "network-only",
    variables: {
      where: {
        id: lpc.id
      }
    }
  });

  const updateStateLpcConsultation = useMutation(UPDATE_STATE_LPC_CONSULTATION);

  const handleUpdate = async id => {
    const {
      data: { updateLpcConsultation }
    } = await updateStateLpcConsultation({
      variables: {
        data: {
          id
        }
      }
    });
    console.log(updateLpcConsultation);
    if (updateLpcConsultation.success) {
      refetch();
    }
  };

  return (
    <div className="fadeIn animated">
      <ReactTable
        loading={isLoading}
        data={data.getPendingConsultation || []}
        defaultPageSize={10}
        columns={[
          {
            Header: "Paciente",
            accessor: "name"
          },
          {
            Header: "Cedula",
            accessor: "document",
            Cell: ({ original }) => {
              return <div>{original?.document ?? "N/A"}</div>;
            },
            maxWidth: 150
          },
          {
            Header: "Edad",
            accessor: "age",
            maxWidth: 100
          },
          {
            Header: "Sexo",
            accessor: "gender",
            maxWidth: 100
          },
          {
            Header: "Opciones",
            className: "text-center",
            Cell: ({ original }) => {
              return (
                <div>
                  <Button
                    type="button"
                    style={{ marginRight: "5px" }}
                    className={`btn-green`}
                    onClick={() => {
                      history.push(
                        `/lpc/medical_appointment/appointment/${original.id}`,
                        {
                          original: lpc
                        }
                      );
                    }}
                  >
                    Volver a Consultar
                  </Button>

                  <Button
                    type="button"
                    onClick={() => {
                      handleUpdate(original.id);
                    }}
                    className="btn-red"
                  >
                    Finalizar Consulta
                  </Button>
                </div>
              );
            }
          }
        ]}
      />
    </div>
  );
};
