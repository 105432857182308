import React, { useEffect, useMemo, useState } from "react";
import {
  useLocation,
  useParams
} from "react-router-dom/cjs/react-router-dom.min";
import {
  Button,
  Card,
  CardBody,
  Col,
  Input,
  Label,
  Row,
  TabContent
} from "reactstrap";
import CardHeaderTabs from "../../../../@meds/components/molecules/card-header-tabs";
import ReactSelect from "react-select";
import { AppointmentGroups } from "./tabs/groups";
import { PreliminaryDiagnosis } from "./tabs/preliminaryDiagnosis";
import { useMutation, useQuery } from "react-apollo-hooks";
import {
  CREATE_LPC_HISTORY,
  GET_ARS,
  GET_LPC_MEDICAL_STAFF,
  GET_LPC_PARAMETERS_GROUPS,
  GET_ONE_LPC_CONSULTATION,
  PATIENT_BY_DOCUMENT,
  UPDATE_LPC_HISTORY
} from "../../utils/queries";
import ReactDatePicker from "react-datepicker";
import { useLpcStore } from "../../hooks/useLpcStore";
import { CREATE_TEMPORAL_PATIENT } from "../../../../containers/CheckIn/components/CheckInPatientNotExists/queries";
import { useAppContext } from "../../../../AppContext";
import { toast } from "react-toastify";

export const MedicalAppointment = ({ history }) => {
  const { id } = useParams();
  const [medicalStaff, setMedicalStaff] = useState(null);
  const [createEvent, setCreateEvent] = useState(false);
  // Mutations
  const createLpcEvent = useMutation(CREATE_LPC_HISTORY);
  const updateLpcHistory = useMutation(UPDATE_LPC_HISTORY);
  const {
    document,
    name,
    lastName,
    birthdate,
    setDocument,
    setName,
    setLastName,
    age,
    setAge,
    documentType,
    gender,
    setGender,
    setDocumentType,
    ars,
    affiliateId,
    parameter,
    clearFields,
    eventId,
    programId,
    icdCodeId,
    clearFields2,
    setFields
  } = useLpcStore();

  const [parametersGroup, setParametersGroup] = useState([
    {
      id: 0,
      name: "Diagnostico Preliminar"
    }
  ]);
  const {
    state: { original } = {} // Valor por defecto para evitar errores
  } = useLocation() || {};
  const [shouldSearch, setShoudSearch] = useState(false);
  const genders = [
    {
      value: 1,
      label: "Masculino"
    },
    {
      value: 2,
      label: "Femenino"
    },

    {
      value: 3,
      label: "No Definido"
    },

    {
      value: 4,
      label: "Ambos Sexos"
    }
  ];
  const [editable, setEditable] = useState(false);
  const [activeTab, setActiveTab] = useState(1);
  const tabs = [
    {
      id: 1,
      name: "Grupo 1"
    },
    {
      id: 2,
      name: "Grupo 2"
    },
    {
      id: 3,
      name: "Diagnostico Preliminar"
    }
  ];

  const createTemporalPatient = useMutation(CREATE_TEMPORAL_PATIENT);
  const { data: arsData, isLoading: arsLoading } = useQuery(GET_ARS, {
    fetchPolicy: "network-only"
  });
  const { data: lpcData, isLoading: lpcLoading } = useQuery(
    GET_ONE_LPC_CONSULTATION,
    {
      fetchPolicy: "network-only",
      skip: !id,
      variables: {
        where: { id: +id }
      }
    }
  );
  const { data, isLoading } = useQuery(GET_LPC_PARAMETERS_GROUPS, {
    fetchPolicy: "network-only",
    variables: {
      where: {
        id: original?.productId
      }
    }
  });
  const { state: ctx } = useAppContext();

  const { data: staffData, isLoading: staffLoading } = useQuery(
    GET_LPC_MEDICAL_STAFF,
    {
      fetchPolicy: "network-only",
      variables: {
        where: {
          id: ctx?.user?.id
        }
      }
    }
  );

  useEffect(() => {
    if (staffData && !staffLoading) {
      setMedicalStaff(staffData.getLpcMedicalStaff);
    }
  }, [staffData, staffLoading]);

  const { data: patientData, loading: patientLoading, refetch } = useQuery(
    PATIENT_BY_DOCUMENT,
    {
      fetchPolicy: "network-only",
      skip: !shouldSearch,
      variables: {
        where: {
          document
        }
      }
    }
  );

  const handleCreateAffiliate = async (editable, documentTypeId) => {
    if (editable) {
      const payload = {
        firstName: name,
        lastName: lastName,
        documentTypeId: +documentTypeId,
        identificationCard: document ?? 1,
        arsId: +ars,
        dateOfBirth: birthdate,
        centerId: ctx?.user?.centers[0] ?? 1,
        genderId: gender
      };

      try {
        const {
          data: { createPatientForCheckIn }
        } = await createTemporalPatient({
          variables: { data: { ...payload } }
        });

        if (createPatientForCheckIn) {
          refetch();
          setEditable(false);
          return true;
        } else {
          toast.error(
            "Error al crear el paciente temporal. Por favor, intente nuevamente."
          );
          return false;
        }
      } catch (error) {
        toast.error(
          "Error al crear el afiliado. Por favor, intente nuevamente."
        );
        return false;
      }
    }
    return false;
  };

  const handleSearch = () => {
    setShoudSearch(true);
  };

  const calculateAge = birth => {
    if (!birth) return setAge(0);
    if (birth) {
      const birthday = new Date(birth);
      var ageDifMs = Date.now() - birthday.getTime();
      var ageDate = new Date(ageDifMs);
      setAge(Math.abs(ageDate.getUTCFullYear() - 1970));
    }
  };

  const validate = arr => {
    const isEditable = !Array.isArray(arr) || arr.length === 0;
    setEditable(isEditable);
  };

  useEffect(() => {
    if (patientData && !patientLoading) {
      const patientList = patientData?.getPatientbyDocument || [];
      validate(patientList);
      if (Array.isArray(patientList) && patientList.length > 0) {
        const patient = patientList[0];
        setFields({
          name: patient.firstName || "",
          lastName: patient.lastName || "",
          gender: patient.gender || 0,
          birthdate: patient.birthdate || null,
          documentType: patient.documentType || 0,
          affiliateId: patient.id || 0,
          ars: patient.arsId || null
        });
        calculateAge(patient.birthDate || "");
        setShoudSearch(false);
      } else {
        clearFields2();
      }
    }
    // eslint-disable-next-line
  }, [patientData, patientLoading]);

  useEffect(() => {
    if (lpcData && !lpcLoading) {
      const patient = lpcData.getOneLpcConsultation?.affiliate;
      setFields({
        name: patient?.firstName || "",
        lastName: patient?.lastName || "",
        gender: patient?.gender || 0,
        document: patient?.document,
        birthdate: patient?.birthDate || null,
        documentType: +patient?.documentType || 0,
        affiliateId: patient?.id || 0,
        ars: patient?.arsId || null,
        icdCodeId: patient?.icdCode || null,
        programId: patient?.attentionProgram || null,
        eventId: patient?.eventId || 0
      });
      calculateAge(patient?.birthdate || "");
      setShoudSearch(false);
    }
    // eslint-disable-next-line
  }, [lpcData, lpcLoading]);

  const documentTypes = [
    {
      value: 1,
      label: "Cedula"
    },
    {
      value: 2,
      label: "Pasaporte"
    }
  ];

  const validateName = (name, lastName) => {
    if (!name || !lastName) return "";
    return name + " " + lastName;
  };

  const finalParametersGroup = useMemo(() => {
    const dynamicTabs = parametersGroup.filter(tab => tab.id !== 0);
    const fixedTab = parametersGroup.find(tab => tab.id === 0);
    return [...dynamicTabs, fixedTab];
  }, [parametersGroup]);

  const handleUpdateLpcHistory = async () => {
    const payload = {
      date: new Date(),
      centerId: 1,
      patientId: affiliateId,
      eventTypeId: 2,
      specialityId: medicalStaff.specialityId,
      medicalStaffId: medicalStaff.medicalStaffId,
      appointmentId: original?.productId,
      arsId: ars
    };
    const lpcConsultation = {
      lpcId: original.id,
      affiliateId,
      document,
      age,
      programId,
      icdCodeId
    };

    const {
      data: { updateLpcC }
    } = await updateLpcHistory({
      variables: {
        data: {
          event: payload,
          parameters: parameter,
          lpcConsultation
        },
        where: {
          id: +id
        }
      }
    });

    if (updateLpcC.success) {
      history.replace({
        pathname: "/lpc/medical_appointment/patientRelationship",
        state: { original }
      });
    } else {
      toast.error("Error al crear el evento. Por favor, intente nuevamente.");
      console.error(updateLpcC.message);
    }
  };
  const handleCreateLpcEvent = async () => {
    const payload = {
      date: new Date(),
      centerId: 1,
      patientId: affiliateId,
      eventTypeId: 2,
      specialityId: medicalStaff.specialityId,
      medicalStaffId: medicalStaff.medicalStaffId,
      appointmentId: original?.productId,
      arsId: ars
    };
    const lpcConsultation = {
      lpcId: original.id,
      affiliateId,
      document,
      age,
      programId,
      icdCodeId
    };

    const {
      data: { createLpcAppointmentHistories }
    } = await createLpcEvent({
      variables: {
        data: {
          event: payload,
          parameters: parameter,
          lpcConsultation
        }
      }
    });
    if (createLpcAppointmentHistories.success) {
      history.replace({
        pathname: "/lpc/medical_appointment/patientRelationship",
        state: { original }
      });
    } else {
      toast.error("Error al crear el evento. Por favor, intente nuevamente.");
      console.error(createLpcAppointmentHistories.message);
    }
  };

  const handleSaving = async (editable, documentType) => {
    setCreateEvent(false);
    if (!editable && !affiliateId) {
      return toast.error(
        "Debe buscar un paciente antes de continuar con esta accion"
      );
    }
    if (editable && !name) {
      return toast.error(
        "Debe ingresar el nombre del afiliado antes de continuar con esta accion"
      );
    }
    if (editable && name.length < 2) {
      return toast.error("Escriba su nombre completo");
    }
    if (editable && !lastName) {
      return toast.error(
        "Debe ingresar el apellido  del afiliado antes de continuar con esta accion"
      );
    }

    if (editable && lastName.length < 2) {
      return toast.error(
        "Debe ingresar el apellido  del afiliado antes de continuar con esta accion"
      );
    }
    if (editable && !gender) {
      return toast.error(
        "Debe seleccionar el genero del afiliado antes de continuar con esta accion"
      );
    }
    if (editable && !document) {
      return toast.error(
        "Debe ingresar el numero de documento del afiliado antes de continuar con esta accion"
      );
    }
    if (editable && !birthdate) {
      return toast.error(
        "Debe ingresar la fecha de nacimiento del afiliado antes de continuar con esta accion"
      );
    }

    let affiliateCreated = true;
    if (editable) {
      affiliateCreated = await handleCreateAffiliate(editable, documentType);
    }

    // Si se creó el afiliado correctamente o no era necesario crearlo, creamos el evento LPC
    if (affiliateCreated) {
      setCreateEvent(true);
    } else {
      toast.error("No se pudo crear el afiliado. Operación cancelada.");
    }
  };

  useEffect(() => {
    const createnewEventLpc = async () => {
      try {
        await handleCreateLpcEvent();
      } catch (error) {
        console.error("Error al crear el evento LPC:", error);
        toast.error(
          "Hubo un problema al crear el evento LPC. Por favor, intente nuevamente."
        );
      }
    };

    if (affiliateId !== 0 && createEvent) {
      createnewEventLpc();
    }
    //eslint-disable-next-line
  }, [affiliateId, createEvent]);

  useEffect(() => {
    if (!isLoading && data?.getParameterGroupLpc) {
      const newGroups = Array.isArray(data.getParameterGroupLpc)
        ? data.getParameterGroupLpc
        : [data.getParameterGroupLpc];
      setParametersGroup(prev => [
        ...newGroups.filter(group => group.id !== 0),
        ...prev
      ]);
    }
  }, [isLoading, data]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    calculateAge(birthdate);
    //eslint-disable-next-line
  }, [birthdate]);

  useEffect(() => {
    // limpiar campos cuando el componente se desmonta
    return () => clearFields();
  }, [clearFields]);

  useEffect(() => {
    if (!original) {
      history.replace("/lpc/medical_appointment");
    }
    //eslint-disable-next-line
  }, []);
  return (
    <div className="fadeIn animated">
      <div className="card">
        <div className="card-body">
          <Row>
            <Col>
              <Row style={{ marginBottom: "20px" }}>
                <Col md="2">
                  <Label>LPC o Jornada</Label>
                  <Input value={original?.name ?? ""} disabled={true} />
                </Col>
              </Row>
              <Row>
                <Col md="2">
                  <Label>Tipo de Documento</Label>
                  <ReactSelect
                    value={documentTypes.find(e => e.value === documentType)}
                    options={documentTypes}
                    isDisabled={id}
                    onChange={e => {
                      setDocumentType(e.value);
                    }}
                  />
                </Col>
                <Col md="2">
                  <Label>No. Documento</Label>
                  <Input
                    placeholder={"Documento"}
                    value={document || ""}
                    maxLength={11}
                    disabled={documentType === 0 || id > 0}
                    onChange={e => {
                      setDocument(e.target.value);
                    }}
                  />
                </Col>
                {!id && (
                  <Col style={{ display: "flex", alignItems: "flex-end" }}>
                    <Button onClick={handleSearch}>
                      <i className="fa fa-search mr-2"></i>
                      Buscar
                    </Button>
                  </Col>
                )}
              </Row>
              <Row>
                {editable ? (
                  <>
                    <Col md="2">
                      <Label>Nombre</Label>
                      <Input
                        value={name || ""}
                        placeholder={"Nombre"}
                        disabled={!editable}
                        onChange={e => {
                          setName(e.target.value);
                        }}
                      />
                    </Col>
                    <Col md="2">
                      <Label>Apellido</Label>
                      <Input
                        value={lastName || ""}
                        placeholder={"Apellido"}
                        disabled={!editable}
                        onChange={e => {
                          setLastName(e.target.value);
                        }}
                      />
                    </Col>
                  </>
                ) : (
                  <Col md="3">
                    <Label>Nombres y Apellidos</Label>
                    <Input
                      value={validateName(name, lastName)}
                      placeholder={"Nombre y Apeliidos"}
                      disabled={!editable}
                      onChange={e => {
                        setName(e.target.value);
                        setLastName(e.target.value);
                      }}
                    />
                  </Col>
                )}
                <div style={{ width: "140px", marginRight: "10px" }}>
                  <Label>Nacimiento</Label>
                  <ReactDatePicker
                    selected={editable && birthdate}
                    value={
                      !editable && birthdate
                        ? new Date(birthdate).toLocaleDateString("es-ES")
                        : birthdate
                    }
                    onChange={date => {
                      setFields({
                        birthdate: date
                      });
                    }}
                    disabled={!editable}
                    placeholderText={"Fecha Nacimiento"}
                    dateFormat={"dd/MM/yyyy"}
                    showMonthDropdown
                    className={"form-control text-center"}
                  />
                </div>
                <div style={{ width: "100px" }}>
                  <Label>Edad</Label>
                  <Input disabled={true} value={age} />
                </div>
                <Col md="2">
                  <Label>Sexo</Label>
                  <ReactSelect
                    isDisabled={!editable}
                    value={genders.find(e => e.value === gender)}
                    options={genders}
                    onChange={e => setGender(e.value)}
                  />
                </Col>
              </Row>
              <Row>
                <Col md="2">
                  <Label>Aseguradora</Label>

                  <ReactSelect
                    isLoading={arsLoading}
                    isDisabled={!editable}
                    value={arsData?.getArs?.find(e => e.value === ars)}
                    options={arsData.getArs}
                    onChange={e => setFields({ ars: e.value })}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          <Row style={{ marginTop: "15px" }}>
            <Col>
              <Card>
                <CardHeaderTabs
                  active={activeTab}
                  onClick={tap => setActiveTab(tap)}
                  tabs={parametersGroup || tabs}
                />
                <CardBody>
                  <TabContent className="border-0" activeTab={activeTab}>
                    {finalParametersGroup.map(group => (
                      <div key={group.id}>
                        {group.id === activeTab ? (
                          group.id === 0 ? (
                            <PreliminaryDiagnosis />
                          ) : (
                            <AppointmentGroups
                              groupId={group.id}
                              patientId={affiliateId}
                              eventId={eventId}
                            />
                          )
                        ) : null}
                      </div>
                    ))}
                  </TabContent>
                  <Row style={{ marginTop: "20px" }}>
                    <Col>
                      <Button
                        color="success"
                        onClick={() => {
                          id
                            ? handleUpdateLpcHistory()
                            : handleSaving(editable, documentType);
                        }}
                      >
                        {!id ? "Crear" : "Modificar"}
                      </Button>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};
