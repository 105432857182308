import gql from "graphql-tag";

export const CREATE_APPOINTMENT_HISTORY = gql`
  mutation CreateAppointmentHistory($data: AppointmentHistoryCreateInput!) {
    createAppointmentHistory(data: $data) {
      id
      isDhnAuthorize {
        medicines
      }
      codes {
        imageCode {
          code
          expirationDate
        }
        labCode {
          code
          expirationDate
        }
        licencesCode {
          code
          expirationDate
        }
        medicine {
          code
          expirationDate
        }
        opt {
          code
          expirationDate
        }
        refCode {
          code
          expirationDate
        }
        xRayCode {
          code
          expirationDate
        }
      }
    }
  }
`;

export const GET_ICD10_CODES = gql`
  query GetICD10Codes($where: ICD10WhereInput!) {
    icd10Codes(where: $where) {
      id
      code
      description
    }
  }
`;

//crea el precio en ars product
export const CREATE_ARS_PRODUCT = gql`
  mutation CreateARSProduct($data: ARSProductCreateInput!) {
    createARSProduct(data: $data) {
      id
    }
  }
`;

export const GET_MEDICINE_PRODUCTS = gql`
  query getMedicinesProduct(
    $where: MedicalAttentionProgramPeriodTypeWhereInput
  ) {
    getMedicinesProduct(where: $where) {
      id
      name
    }
  }
`;

export const GET_LICENSES_WITH_DAYS = gql`
  query getLicensesWithDays {
    getLicensesWithDays {
      id
      name
      description
      days {
        label
        value
      }
    }
  }
`;

export const GET_PATIENT_INFORMATION = gql`
  query GetPatientInformation(
    $ahwhere: AppointmentHistoryWhereInput!
    $pawhere: PatientAllergyWhereInput!
    $pwhere: PatientProgramWhereInput!
  ) {
    histories: appointmentsHistories(where: $ahwhere) {
      id
      date
      reason
      diseaseHistory
      diagnosticProcedure
      diagnosticProductId
      diagnostic {
        code
        description
      }

      medicalStaff {
        name
      }
    }

    patientAllergies(where: $pawhere) {
      allergy {
        id
        name
      }
    }

    programs: patientMedicalAttentionPrograms(where: $pwhere) {
      requestDate
      startingPeriod
      startingPeriodName

      medicalStaff {
        name
      }

      status {
        id
        name
      }

      medicalAttentionProgram {
        id
        name
        dhnConfig
      }
    }
  }
`;

export const GET_ARS_PRICE = gql`
  query ArsProduct($where: ARSProductWhereUniqueInput!) {
    arsProduct(where: $where) {
      productId
      amount
      copago
      coverageAmount
      amountPP
      copagoPP
      coverageAmountPP
    }
  }
`;

export const GET_ARS_PRICE_FOR_APPOINTMENT = gql`
  query arsProductForAppointmnet($where: ARSProductWhereUniqueInput!) {
    arsProductForAppointmnet(where: $where) {
      amount
      amountPP
      copago
      copagoPP
      coverageAmount
      coverageAmountPP
      productId
      productTypeId
      coveragePorcent
      coveragePorcentPP

      typeName
      hasPriceRange
      priceRange
      noAsegurado
    }
  }
`;

export const GET_APPOINTMENT_INTERNAL = gql`
  query GetAppointmentInternal(
    $iwhere: ProductWhereInput
    $lwhere: ProductWhereInput
    $pwhere: ProductWhereInput
    $xwhere: ProductWhereInput
    $awhere: AllergiesWhereInput!
    $mmwhere: MedicineWhereInput
    $rfwhere: MedicalStaffWhereInput!
    $mswhere: MedicalStaffWhereUniqueInput!
    $mwhere: MedicalAttentionProgramWhereInput
    $where: AppointmentInternalWhereUniqueInput!
    $excludeRelations: Boolean
  ) {
    staff: medicalStaff(where: $mswhere) {
      id
      name
      exequatur
      specialityId

      speciality {
        id
        name
      }
    }

    referrals: medicalStaffs(where: $rfwhere) {
      id
      name
      exequatur
      specialityId

      speciality {
        id
        name
      }
    }

    pams: medicalAttentionPrograms(where: $mwhere) {
      id
      name
      period
      requiredStartingPeriod

      periodType {
        name
      }

      arses {
        id
        name
      }
    }

    allergies: getAllergies(where: $awhere) {
      id
      name
    }

    labExams: getProductsForAppointment(where: $lwhere) {
      id
      name
      productTypeId
      hasFasting
    }

    imgExams: getProductsForAppointment(where: $iwhere) {
      id
      name
      productTypeId
    }

    xRayExams: getProductsForAppointment(where: $xwhere) {
      id
      name
      productTypeId
    }

    procedures: products(where: $pwhere, excludeRelations: $excludeRelations) {
      id
      name
      diagnosticRequiered
      productTypeId
      hasITBIS

      productType {
        id
        canModifyQuantity
      }

      appointmentProducts {
        appointmentId
      }
    }

    # procedures: getProductsForAppointment(where: $pwhere) {
    #   id
    #   name
    #   diagnosticRequiered
    #   productTypeId
    #   hasITBIS

    #   appointmentProducts {
    #     appointmentId
    #   }
    # }

    medicines: medicines(where: $mmwhere) {
      id
      name
    }

    appointment: appointmentInternal(where: $where) {
      id
      appointmentId
      duration

      optometry {
        id
        esferaRight
        esferaLeft
        cilindroRight
        cilindroLeft
        ejeRight
        ejeLeft
        adicionLeft
        adicionRight
        eventId
        historyId
        glasses
        pupillaryDistribution
        bifocalHeight
        other
        simpleVision
        progressive
        invisible
        flatTop
        date
      }

      licenses {
        id
        description
        days
        type
        eventId
        historyId
        date
      }

      medicineFilterPam {
        id
        name
      }

      options {
        id
        name
        value
        parameterTypeId
      }

      appointmentTabsActive {
        id
        name
        enabled
        tabsId
      }

      pams {
        id
        name
        period
        requiredStartingPeriod
        periodType {
          name
        }
        noApply
      }

      ImgExam {
        id
        name
        imgId
        appointmentId
        expirationDate
        code
      }

      XRayExam {
        id
        name
        imgId
        appointmentId
        expirationDate
        code
      }

      LabExam {
        id
        name
        labId
        appointmentId
        expirationDate
        code
      }

      appointmentReferral {
        name
        medicalId
        speciality
        specialityId
      }

      getAppointmentHistoryProduct {
        id
        date
        productName
        productTypeId
        eventId
        quantity
        diagnosticProductId
        resultProcedures
        conclusionProcedure
        diagnosticProcedure
        diagnosticProcedureName
        patientId
        diagnosticRequiered
        code
        tarifa
        copago
        cobertura
        hasITBIS
        hasPriceRange
        rangePrice
        coberturaPor

        hasPriceChanged
        priceChangedId
        priceChanged
        copagoChanged
        observation
        dicom {
          url
        }
      }
      appointmentProducts {
        appointmentId
      }
      center {
        id
        name
        address
        phone
        email
      }

      patient {
        id
        firstName
        middleName
        lastName
        lastName2
        dateOfBirth
        identificationCard

        gender {
          name
        }

        ars {
          id
          name
          # dhnConfig
          idDhn
        }
      }

      parameters {
        required
        id
        name
        text
        observation
        minimum
        maximum
        yesOrNo
        maximumAge
        minimumAge
        positiveOrNegative
        unitOfMeasurement

        type {
          id
          name
        }

        groups {
          medicalParameterGroupId
        }
      }

      medicalParameterGroups {
        id
        name
      }

      history {
        id
        reason
        diseaseHistory

        diagnostic {
          id
          code
          description
        }

        diagnostic2 {
          id
          code
          description
        }

        diagnostic3 {
          id
          code
          description
        }

        diagnosticProcedureName {
          id
          code
          description
        }

        appointmentHistoryProduct {
          id
          date
          diagnosticProcedure
          diagnosticProductId
          eventId
          patientId
          conclusionProcedure
          resultProcedure
          quantity
          diagnosticProcedureName {
            # ICD10Code{
            id
            description
            code
            # }
          }

          productName {
            id
            name
            diagnosticRequiered
            productTypeId
          }
        }
        # medicalLicensesType {
        #   id
        #   name
        #   description
        #   enabled
        #   maxDays
        # }
        parameters {
          id
          eventId
          patientId
          medicalParameterTypeId
          positiveOrNegative
          yesOrNo
          text
          observation
          absolute
          percentage
          medicalParameterId
          appointmentHistoryId
        }

        imageExams {
          id
          name
        }
        event {
          specialityId
          medicalAttentionProgramId
          details {
            productId
          }
        }

        laboratoryExams {
          id
          name
        }

        medicines {
          dose
          duration

          medicine {
            id
            name
          }
        }

        procedures {
          product {
            id
            name
            diagnosticRequiered
            productTypeId
            hasITBIS
          }
        }

        physicalExam {
          id
          skin
          head
          neck
          heart
          lungs
          abdomen
          upperLimbs
          lowerLimbs
        }
      }
    }
  }
`;

export const SEND_DATA_TO_SANTE = gql`
  query sendDataToSante($data: sendDataToSanteI) {
    sendDataToSante(data: $data)
  }
`;
