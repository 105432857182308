import gql from "graphql-tag";
import React from "react";
import { useQuery } from "react-apollo-hooks";
import ReactTable from "react-table-v6";
import { Button, Card, CardBody, Label } from "reactstrap";
import { useHistory, useParams } from "react-router";
import moment from "moment";

const QUERY = gql`
  query getAdherenceRateByProgram($where: WhereGetRegisteredPatient) {
    adherenceRateByProgram: getAdherenceRateByProgram(where: $where) {
      status
      activationDate
      patientName
      statusId
      pamId
      patientId
      date
      noAffiliate
    }
    programs: medicalAttentionPrograms {
      id
      name
    }
  }
`;
export default function Table() {
  const { programId, startDate, endDate } = useParams();
  const { data, loading } = useQuery(QUERY, {
    variables: {
      where: {
        programId: parseInt(programId),
        startDate,
        endDate
      }
    },
    fetchPolicy: true
  });
  const history = useHistory();

  const programName =
    data.programs && data.programs.find(p => p.id === parseInt(programId)).name;

  return (
    <div className="animated fadeIn">
      <Card>
        <CardBody>
          <Label className="font-weight-bold">{programName}</Label>
          <ReactTable
            data={data.adherenceRateByProgram}
            defaultPageSize={10}
            loading={loading}
            loadingText="Cargando"
            columns={[
              {
                Header: "Paciente",
                accessor: "patientName"
              },
              {
                Header: "Afiliado",
                accessor: "noAffiliate",
                className: "text-right"
              },
              {
                Header: "Inicio",
                accessor: "activationDate",
                className: "text-center"
              },
              {
                Header: "Estatus",
                accessor: "status"
              },
              {
                Header: "Ultima Cita",
                className: "text-center",
                Cell: ({ original: { date } }) => (
                  <span>{date && moment(date).format("DD-MM-YYYY")}</span>
                )
              },
              {
                Header: "Opciones",
                Cell: ({ original }) => (
                  <Button
                    onClick={() => {
                      history.push(
                        `/dashboard/performance-metrics-pyp/adherence-rate/program/${programId}/startDate/${startDate}/endDate/${endDate}/patient/${original.patientId}`
                      );
                    }}
                    color="success"
                  >
                    {" "}
                    Ver detalle
                  </Button>
                )
              }
            ]}
          />
        </CardBody>
      </Card>
    </div>
  );
}
